import React, { useEffect, useState } from "react"
import { Button, Form } from "react-bootstrap"
import CategoryInterface from "../../interfaces/category"
export default function CategoryModalForm({
  selectedCategory,
  setSelectedCategory,
  onSave,
}: {
  selectedCategory: CategoryInterface
  setSelectedCategory: React.Dispatch<React.SetStateAction<CategoryInterface>>

  onSave: () => void
}) {
  const [allowEdit,setAllowEdit]=useState(false);
  useEffect(()=>{
    if(selectedCategory.id===''){
      setAllowEdit(true);
    }
  },[])
  function onSubmit(e: any) {
    e.preventDefault()
    onSave()
  }
  return (
    <Form onSubmit={onSubmit}>
      <Form.Group>
        <Form.Label>ID</Form.Label>
        <Form.Control
          placeholder="ID"
          disabled={!allowEdit}
          value={selectedCategory.id}
          onChange={e =>
            setSelectedCategory(old => ({ ...old, id: e.target.value }))
          }
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Name</Form.Label>
        <Form.Control
          placeholder="Name"
          value={selectedCategory.name}
          onChange={e =>
            setSelectedCategory(old => ({ ...old, name: e.target.value }))
          }
        />
      </Form.Group>

      <Button type="submit">Save</Button>
    </Form>
  )
}
