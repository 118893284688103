import React from "react"
import { useState } from "react"
import { Modal, Button } from "react-bootstrap"
import PlanInterface from "../../interfaces/plan"
import PlanCard from "./planCard"
import { defaultPlan } from "../../interfaces/plan"
import PlanModalForm from "./planForm"
import { deletePlan, setPlan } from "../../utils/fire"

export default function PlansTab({
  plans,
  categories,
  setPlans,
}: {
  plans: PlanInterface[]
  categories: { id: string; name: string }[]
  setPlans: React.Dispatch<React.SetStateAction<PlanInterface[]>>
}) {
  const [selectedPlan, setSelectedPlan] = useState<PlanInterface>(defaultPlan)
  const [showModal, setShowModal] = useState(false)
  function onEdit(plan: PlanInterface) {
    setSelectedPlan(plan)
    setShowModal(true)
  }
  function onAdd() {
    setSelectedPlan(defaultPlan)
    setShowModal(true)
  }
  function onDelete(plan:PlanInterface){
    deletePlan(plan).then(()=>{
      setPlans(old=>old.filter(plan2=>plan2.id!==plan.id))
    })
  }
  function onSave() {
    setPlan(selectedPlan).then(() => {
      setPlans(old => {
        let foundIndex = old.findIndex(ele => ele.id === selectedPlan.id)
        if (foundIndex!==-1) {
          old[foundIndex] = { ...selectedPlan }
          return old
        } else return [...old, { ...selectedPlan }]
      })
      setShowModal(false);
    })
  }
  return (
    <div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>Edit Plan</Modal.Header>
        <Modal.Body>
          <PlanModalForm
            categories={categories}
            onSave={onSave}
            setSelectedPlan={setSelectedPlan}
            selectedPlan={selectedPlan}
          />
        </Modal.Body>
      </Modal>
      <div className="m-2">
        Add Plan <Button className='rounded-circle m-2' onClick={onAdd}>+</Button>
      </div>

      <div className="d-flex flex-wrap justify-content-center">
        
        {plans.map(plan => (
          <span key={plan.id} className="m-2">
            <PlanCard onDelete={onDelete} onEdit={onEdit} plan={plan} />
          </span>
        ))}
      </div>
    </div>
  )
}
