import React from "react"
import CategoryInterface from "../../interfaces/category"

export default function CategoryCard({
  category,
  onEdit,
  onDelete,
}: {
  category: CategoryInterface
  onEdit: any
  onDelete: any
}) {
  return (
    <div key={category.id} className={"plan-item m b-4 mb-lg-0  flex-column border border-dark"}>
      <div className="p-3 flex-grow-1">
        <div className="plan-heading text-center">Name:{category.name}</div>
        <div className="plan-price text-center">ID:{category.id}</div>
        <div className="text-center">
          <img alt="edit" className="pointer-on-hover m-2" onClick={()=>onEdit(category)} src="https://img.icons8.com/material-outlined/24/000000/edit--v1.png"/>
          <img alt="delete" className="pointer-on-hover m-2" onClick={()=>onDelete(category)} src="https://img.icons8.com/material-rounded/24/000000/delete-forever.png"/>
        </div>
      </div>
    </div>
  )
}
