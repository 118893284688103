import React, { useEffect, useState } from "react"
import { Button, Form } from "react-bootstrap"
import PlanInterface from "../../interfaces/plan"
import plus from '../../images/plus.png'
import { uploadImage } from "../../utils/fire"
export default function PlanModalForm({ selectedPlan, setSelectedPlan, categories, onSave }: {
  selectedPlan: PlanInterface
  setSelectedPlan: React.Dispatch<React.SetStateAction<PlanInterface>>
  categories: { id: string; name: string }[]
  onSave: () => void
}) {
  const [image, setImage] = useState()
  const [allowEdit,setAllowEdit]=useState(false);
  useEffect(()=>{
    if(selectedPlan.id===''){
      setAllowEdit(true);
    }
  },[])
  async function onImageChange(e: any) {
    const file = e.target.files[0]
    if (!file.type.startsWith("image/")) {
      alert("Kindly provide a Image file")
      return
    }
    const imageURL=await uploadImage(selectedPlan,file);
    setSelectedPlan(old=>({...old,imageURL}))
    setImage(file)
  }

  async function onSubmit(e: any) {
    e.preventDefault()
    onSave()
  }

  return (
    <Form onSubmit={onSubmit}>
      <Form.Group>
        <Form.Label>ID</Form.Label>
        <Form.Control
          placeholder="ID"
          disabled={!allowEdit}
          value={selectedPlan.id}
          onChange={e =>
            setSelectedPlan(old => ({ ...old, id: e.target.value }))
          }
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Name</Form.Label>
        <Form.Control
          placeholder="Name"
          value={selectedPlan.name}
          onChange={e =>
            setSelectedPlan(old => ({ ...old, name: e.target.value }))
          }
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Price</Form.Label>
        <Form.Control
          placeholder="Price"
          type="number"
          value={selectedPlan.price}
          onChange={e =>
            setSelectedPlan(old => ({
              ...old,
              price: parseInt(e.target.value)
            }))
          }
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Included in the package</Form.Label>
        <Form.Control
          as="textarea"
          placeholder="Comma Seperated"
          value={selectedPlan.included.join(", ")}
          onChange={e =>
            setSelectedPlan(old => ({
              ...old,
              included: e.target.value.split(",").map(item => item.trim())
            }))
          }
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Is Hidden</Form.Label>
        <Form.Control
          as="select"
          value={selectedPlan.isHidden.toString()}
          onChange={e =>
            setSelectedPlan(old => ({
              ...old,
              isHidden: e.target.value === "true"
            }))
          }
        >
          <option value="true">True</option>
          <option value="false">False</option>
        </Form.Control>
      </Form.Group>
      <Form.Group>
        <Form.Label>Category</Form.Label>
        <Form.Control
          as="select"
          value={selectedPlan.category}
          onChange={e =>
            setSelectedPlan(old => ({
              ...old,
              category: e.target.value
            }))
          }
        >
          <option value="">No Category</option>

          {categories.map(c => (
            <option value={c.id}>{c.name}</option>
          ))}
        </Form.Control>
      </Form.Group>
      <Form.Group>
        <Form.Label>Priority</Form.Label>
        <Form.Control
          placeholder="Priority"
          type="number"
          value={selectedPlan.priority}
          onChange={e =>
            setSelectedPlan(old => ({
              ...old,
              priority: parseInt(e.target.value)
            }))
          }
        />
      </Form.Group>
      <Form.Group>
        <div
          className="d-flex align-items-center justify-content-center pointer-on-hover"
          style={{
            backgroundColor: "#F4F5F8",
            height: 109,
            width: 92
          }}
          onClick={() =>
            document
              .getElementById("choose_pp")
              ?.click()
          }
        >
          <img
            alt="plus"
            height={image || selectedPlan.imageURL ? 109 : 27}
            width={image || selectedPlan.imageURL ? 92 : 27}
            src={
              image
                ? URL.createObjectURL(image)
                : !selectedPlan.imageURL
                ? plus
                : selectedPlan.imageURL
            }
          />
          <input
            id="choose_pp"
            style={{
              display: "none"
            }}
            type="file"
            accept="image/*"
            onChange={onImageChange}
          />
        </div>
      </Form.Group>
      <Button type="submit">Save</Button>
    </Form>
  )
}
