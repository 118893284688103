export default interface PlanInterface {
  id: string
  name: string
  price: number
  included: string[]
  isHidden: boolean
  imageURL: string
  category: string
  priority: number
}
export const defaultPlan: PlanInterface = {
  id: "",
  name: "",
  price: 0,
  included: [],
  isHidden: false,
  imageURL: "",
  category: "",
  priority: 50,
}
