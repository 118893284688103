import React, { useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { setCategoriesFirebase } from "../../utils/fire"
import CategoryInterface from "../../interfaces/category"
import CategoryCard from "./categoryCard"
import CategoryModalForm from "./categoryForm"

export default function CategoriesTab({
                                        categories,
                                        setCategories
                                      }: {
  categories: CategoryInterface[]
  setCategories: React.Dispatch<React.SetStateAction<CategoryInterface[]>>
}) {
  const [selectedCategory, setSelectedCategory] = useState<CategoryInterface>({
    name: "",
    id: ""
  })
  const [showModal, setShowModal] = useState(false)

  function onEdit(category: CategoryInterface) {
    setSelectedCategory(category)
    setShowModal(true)
  }

  function onAdd() {
    setSelectedCategory({ name: "", id: "" })
    setShowModal(true)
  }

  function onDelete(category: CategoryInterface) {
    setCategories(old => {
      const filtered = old.filter(category2 => category2.id !== category.id)
      setCategoriesFirebase(filtered)
      return filtered
    })
  }

  function onSave() {

    setCategories(old => {
      let foundIndex = old.findIndex(ele => ele.id === selectedCategory.id)
      if (foundIndex !== -1) {
        old[foundIndex] = { ...selectedCategory }
        setCategoriesFirebase(old)
        return old
      } else {
        setCategoriesFirebase([...old, { ...selectedCategory }])
        return [...old, { ...selectedCategory }]
      }
    })
    setShowModal(false);
  }

  return (
    <div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>Edit Plan</Modal.Header>
        <Modal.Body>
          <CategoryModalForm
            onSave={onSave}
            setSelectedCategory={setSelectedCategory}
            selectedCategory={selectedCategory}
          />
        </Modal.Body>
      </Modal>
      <div className="m-2 align-items-center">
        Add Category <Button className="rounded-circle m-2" onClick={onAdd}>+</Button>
      </div>
      <div className="d-flex flex-wrap">
        {categories.map(category => (
          <span key={category.id} className="m-2">
            <CategoryCard onDelete={onDelete} onEdit={onEdit} category={category} />
          </span>
        ))}
      </div>
    </div>
  )
}
