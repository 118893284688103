import React from "react"
import PlanInterface from "../../interfaces/plan"
import { StaticImage } from "gatsby-plugin-image"

export default function PlanCard({ plan,onEdit,onDelete }: { plan: PlanInterface,onEdit:any,onDelete:any }) {
  return (
    <div key={plan.name} className={"plan-item m b-4 mb-lg-0  flex-column border border-dark h-100"}>
      <img className="m-2" src={plan.imageURL} alt={plan.name} />
      <div className="p-3 flex-grow-1">
        <div className="plan-heading text-center">{plan.name}</div>
        <div className="plan-price text-center">${plan.price}</div>
        <hr />
        <div className="text-center">
        <img alt="edit" className="pointer-on-hover m-2" onClick={()=>onEdit(plan)} src="https://img.icons8.com/material-outlined/24/000000/edit--v1.png"/>
        <img alt="delete" className="pointer-on-hover m-2" onClick={()=>onDelete(plan)} src="https://img.icons8.com/material-rounded/24/000000/delete-forever.png"/>
        </div>
        <div className="includes-in-this-package">
          Included in this package:
        </div>
        <div className="includes-list flex-grow-1">
          {plan.included.map(include => (
            <div key={include} className="included-item my-2">
              <StaticImage
                className="mr-2"
                alt="tick"
                placeholder="blurred"
                src="../images/permitPlans/round_tick.png"
              />
              {include}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
