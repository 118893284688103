import React, { useEffect } from "react"
import { useState } from "react"
import PlanInterface from "../interfaces/plan"
import { getCategories, getPlans } from "../utils/fire"
import {Tabs,Tab} from 'react-bootstrap';
import PlansTab from "../components/admin/plansTab";
import CategoriesTab from "../components/admin/categoriesTab";
import './admin.scss';
export default function Admin() {
  const [plans, setPlans] = useState<PlanInterface[]>([])
  const [categories, setCategories] = useState<any[]>([]);
  useEffect(() => {
    getPlans().then((data) => {
      setPlans(data.docs.map((doc) => doc.data() as PlanInterface))
    })
    getCategories().then((data:any)=>{
        setCategories(data.categories);
    })
  }, [])
  return <div>
      <div className="display-4">Admin Console</div>
      <div className="tabs">
          <Tabs defaultActiveKey="plans">
            <Tab title="Plans" eventKey="plans">
                <PlansTab setPlans={setPlans} categories={categories} plans={plans}/>
            </Tab>
            <Tab title="Category" eventKey="category">
                <CategoriesTab setCategories={setCategories} categories={categories}/>
            </Tab>
          </Tabs>
      </div>
  </div>
}
